import { fabric } from 'fabric';
import {
  BIcon,
  BButton,
  BCollapse,
  BContainer,
  BRow,
  BCol,
  BSidebar
} from 'bootstrap-vue';
import { mapGetters } from 'vuex';
import { colorHelpers, canvasHelpers, constants } from '@/utils';
import { imgListData, designIcons } from '@/utils/mediaData';
import { isPC, isHorizontalScreen, isEmpty } from '@/utils/commonUtils';
import SubMenuProductList from './SubMenus/SubMenuProductList';
import SubMenuProductFonts from './SubMenus/SubMenuProductFonts';
import SubMenuShapeIcon from './SubMenus/SubMenuShapeIcon';
import SubMenuIcon from './SubMenus/SubMenuIcon';
import SubMenuImage from './SubMenus/SubMenuImage';
import SubMenuCartoon from './SubMenus/SubMenuCartoon';
import UpComingPopover from '../../../components/UpComingPopover/UpComingPopover.vue';

const moduleName = 'designModule';
const translations = {
  template: 'Ấn phẩm',
  upload: 'Tải lên',
  font: 'Văn bản',
  cartoon: 'Họa tiết',
  image: 'Hình ảnh',
  shapeIcon: 'Khối',
  listIcon: 'Icon',
  line: 'Đường kẻ',
  more: 'Khác',
  icon: 'Biểu tượng'
};

/**
 * All features for this component (for active canvas):
 * - Load a designed template
 * - Add an image from user
 * - Add texts
 * - Add lines
 * - Add shapes
 * - Add designed image
 */
export default {
  name: 'MainToolbar',
  components: {
    BIcon,
    BButton,
    BCollapse,
    BSidebar,
    BContainer,
    BRow,
    BCol,
    SubMenuProductList,
    SubMenuProductFonts,
    SubMenuShapeIcon,
    SubMenuIcon,
    SubMenuCartoon,
    SubMenuImage,
    UpComingPopover
  },
  data() {
    return {
      activeSystemElementCategoryId: '',
      showSystemElementCategoryList: false,
      systemElementMap: {},
      ui: {
        showSubMenu: true,
        closeMainMenu: false,
        activeItem: 'product'
      },
      cartoon: designIcons.cartoonIcon,
      image: designIcons.imageIcon,
      line: designIcons.lineIcon,
      shape: designIcons.shapeIcon,
      listIcon: designIcons.listIcon,
      template: designIcons.templateIcon,
      text: designIcons.textIcon,
      upload: designIcons.uploadIcon,
      more: designIcons.moreIcon,
      icon: designIcons.icon,
      translations,
      isPC,
      isHorizontalScreen,
      noCloseOnRouteChange: true
    };
  },
  computed: {
    ...mapGetters({
      fullName: `${moduleName}/fullName`
    }),
    activeSystemElementCategoryName: function() {
      const res = this.systemElementCategoryList.find(
        c => c.categoryId === this.activeSystemElementCategoryId
      );

      return res ? res.categoryName : '';
    },
    /**
     * UI computed values
     */
    showProductSubMenu() {
      return this.ui.activeItem === 'product';
    },
    showCartoonSubMenu() {
      return this.ui.activeItem === 'cartoon';
    },
    showImageSubMenu() {
      return this.ui.activeItem === 'image';
    },
    showTextSubMenu() {
      const { fontList } = this.$store.state.designModule.appData;

      return fontList && fontList.length && this.ui.activeItem === 'text';
    },
    showShapeSubMenu() {
      return this.ui.activeItem === 'shape';
    },
    showIconSubMenu() {
      return this.ui.activeItem === 'icon';
    }
  },
  methods: {
    /**
     * All helpers, called by other functions
     */
    getActiveCanvas: function() {
      const store = this.$store;
      const { activeCanvas } = store.state[moduleName];

      return activeCanvas;
    },
    setActiveSystemElementCategoryId: function(id) {
      this.activeSystemElementCategoryId = id;
    },
    createImage: function(imgInput, callback) {
      let imgEl = new Image();
      imgEl.src = imgInput;
      imgEl.onload = function() {
        callback && callback(new fabric.Image(imgEl));
      };
    },
    addImageFromURL: function(imgInput) {
      const canvas = this.getActiveCanvas();

      if (!canvas) {
        return;
      }

      this.createImage(imgInput, function(bgImage) {
        const coord = canvasHelpers.getRandomLeftTop({
          canvas,
          obj: {
            width: 50,
            height: 50
          }
        });
        const { top, left } = coord;
        const scaleRatio = canvas.width / bgImage.width / 4;
        bgImage.set({
          scaleX: scaleRatio,
          scaleY: scaleRatio,
          left: Math.min(canvas.width - bgImage.width * scaleRatio, left),
          top: Math.min(canvas.height - bgImage.height * scaleRatio, top)
        });
        canvas.add(bgImage);
      });
    },
    /**
     * END OF All helpers, called by other functions
     */

    /**
     * All UI related functions
     */
    isCloseSubMenu: function(e) {
      this.ui.showSubMenu = e;
      this.$emit('onOpenSidebar', e);
      if (!e) {
        this.ui.activeItem = '';
      }
    },
    onOpenSubMenu: function(itemName) {
      this.ui.showSubMenu = true;
      this.ui.activeItem = itemName;
    },
    onCloseSubMenu: function() {
      this.isCloseSubMenu();
      this.ui.activeItem = '';
    },
    onHideMainToolbar: function() {
      this.ui.closeMainMenu = !this.ui.closeMainMenu;
      this.$emit('onHideMainToolbar', this.ui.closeMainMenu);
    },
    forceShowMainToolbar: function() {
      this.ui.closeMainMenu = false;
    },
    onToggleSystemElementCategoryList: function() {
      this.showSystemElementCategoryList = !this.showSystemElementCategoryList;
    },
    /**
     * END OF All UI related functions
     */

    /**
     * All logic functions
     */
    onPickImage: function() {
      /**
       * TODO
       *
       * DON'T USE $refs because its sucks
       */
      this.$refs.imageFile.click();
    },
    onImagePicked(event) {
      const _this = this;
      const store = this.$store;
      const storeState = store.state;
      const { activeCanvas } = storeState[moduleName];

      let files = event.target.files;
      if (files.length > constants.MAX_FILE_UPLOAD) {
        this.$toastr.e(
          'Số lượng file được chọn 1 lần tối đa là ' +
            constants.MAX_FILE_UPLOAD +
            '. Vui lòng kiểm tra lại.'
        );
        return;
      }

      let acceptedFileType = [
        'image/png',
        'image/jpg',
        'image/jpeg',
        'image/svg+xml',
        'application/json'
      ];

      if (!isEmpty(files[0]) && files[0].type === 'application/json') {
        // const svgURL = URL.createObjectURL(files[0]);

        const file = files[0];

        if (!file) return;

        const reader = new FileReader();
        reader.onload = function(f) {
          let data = f.target.result;

          activeCanvas.loadFromJSON(JSON.parse(data), () => {
            activeCanvas.requestRenderAll();
          });
        };
        reader.readAsText(file);

        // return activeCanvas.loadFromJSON(files[0], () => {
        //   activeCanvas.requestRenderAll();
        // });
      }

      if (!isEmpty(files[0]) && files[0].type === 'image/svg+xml') {
        const svgURL = URL.createObjectURL(files[0]);

        return canvasHelpers.loadSVG({
          canvas: activeCanvas,
          url: svgURL
        });
      }

      let errorFileCount = 0;
      for (let i = 0; i < files.length; i++) {
        let curFile = files[i];
        let fileSizeMb = curFile.size / constants.KB_TO_MB;
        if (fileSizeMb > 2 || acceptedFileType.indexOf(curFile.type) === -1) {
          errorFileCount++;
          continue;
        }

        let reader = new FileReader();
        reader.onload = function() {
          _this.addImageFromURL(this.result);
          event.target.value = null;
        };
        reader.readAsDataURL(curFile);
      }

      if (errorFileCount > 0) {
        this.$toastr.e(
          'Bạn có ' +
            errorFileCount +
            ' file có định dạng không hợp lệ. Vui lòng kiểm tra lại.'
        );
      }
    },
    addLine: function() {
      const canvas = this.getActiveCanvas();

      if (!canvas) {
        return;
      }

      const lineShape = new fabric.Line([50, 100, 200, 200], {
        stroke: '#' + colorHelpers.getRandomColor()
      });
      const coord = canvasHelpers.getRandomLeftTop({ canvas, obj: lineShape });

      lineShape.set(coord);
      canvas.add(lineShape);
    },
    onChooseSystemElementCategory: function(categoryId) {
      this.setActiveSystemElementCategoryId(categoryId);
      this.onToggleSystemElementCategoryList();
    }
    /**
     * END OF All logic functions
     */
  },
  created() {
    /**
     * Initialize state
     */
    this.systemElementMap = imgListData;
    this.systemElementCategoryList = constants.CATEGORY_LIST;

    this.activeSystemElementCategoryId = this.systemElementCategoryList[0].categoryId;
  },
  mounted() {
    window.addEventListener('resize', this.forceShowMainToolbar);
  }
};
