import {
  BModal,
  BButton,
  BContainer,
  BForm,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BFormTextarea,
  BRow,
  BCol,
  BToast,
  BFormInvalidFeedback,
  BIcon,
  BTooltip
} from 'bootstrap-vue';
import { canvasHelpers } from '@/utils';
import { RequestFactory } from '@Request/RequestFactory';
import { onTrackClick } from '@/utils/commonUtils';
import { designIcons } from '@/utils/mediaData';

const moduleName = 'designModule';
const CartRequest = RequestFactory.get('carts');

export default {
  name: 'OrderInformationModal',
  props: [],
  components: {
    BModal,
    BButton,
    BContainer,
    BForm,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BFormTextarea,
    BRow,
    BCol,
    BToast,
    BFormInvalidFeedback,
    BIcon,
    BTooltip
  },
  data() {
    return {
      orderDialogShow: false,
      logoBig: designIcons.logoBig,
      orderImg1: designIcons.orderImg1,
      orderImg2: designIcons.orderImg2,
      print: designIcons.printIcon,
      orderForm: {
        name: '',
        phone: '',
        address: '',
        email: '',
        note: ''
      },
      errors: {}
    };
  },
  methods: {
    /**
     * All UI related functions
     */
    toggleOrderDialog: function() {
      this.orderDialogShow = !this.orderDialogShow;
    },
    /**
     * All logic functions
     */
    onOpenOrderModal: function() {
      /**
       * Show confirm dialog with:
       * - Preview
       * - Action buttons: save/send/etc
       */
      this.toggleOrderDialog();

      // send tracking gtag
      onTrackClick('#id_open-form_email', this.$gtag);
    },
    validateState(field) {
      return this.errors[field] ? false : null;
    },
    onCreateCard(e) {
      e.preventDefault();

      // send tracking gtag
      onTrackClick('#id_sendmail', this.$gtag);

      const {
        name = '',
        phone = '',
        address = '',
        email = '',
        note = ''
      } = this.orderForm;

      this.stripTagsInput();

      const store = this.$store;
      const { canvasList } = store.state[moduleName];

      const formData = {
        name,
        phone,
        address,
        email,
        note,
        products: []
      };

      const totalCanvases = canvasList.length;

      for (let i = 0; i < totalCanvases; i++) {
        const { canvas, templateId } = canvasList[i];
        const svgDataURL = canvasHelpers.exportToSVGDataURL({ canvas });
        const jsonDesign = JSON.stringify(canvas);

        let productItem = {
          productDetailId: templateId,
          json: jsonDesign,
          svg: svgDataURL,
          thumbnail: canvas.toDataURL()
        };

        formData.products = [...formData.products, productItem];
      }

      const _this = this;
      const loader = _this.$loading.show();

      CartRequest.createCart(formData)
        .then(function({ data }) {
          const { message } = data;

          _this.toggleOrderDialog();
          loader.hide();

          _this.$toastr.s(message);
        })
        .catch(function({ response }) {
          const { data } = response;
          const { message, errors } = data;
          _this.errors = errors;

          loader.hide();
          _this.$toastr.e(message);
        });
    },
    stripTagsInput: function() {
      this.orderForm.name = this.orderForm.name.replace(/(<([^>]+)>)/gi, '');
      this.orderForm.phone = this.orderForm.phone.replace(/(<([^>]+)>)/gi, '');
      this.orderForm.address = this.orderForm.address.replace(
        /(<([^>]+)>)/gi,
        ''
      );
      this.orderForm.email = this.orderForm.email.replace(/(<([^>]+)>)/gi, '');
      this.orderForm.note = this.orderForm.note.replace(/(<([^>]+)>)/gi, '');
    }
  }
};
