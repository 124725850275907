var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"canvas"},[_c('div',{staticClass:"group-canvas-wrapper",class:{ mb: !_vm.isPC(_vm.$store) }},[_c('div',{staticClass:"group-canvas-info text-left m-auto",style:({
        width: _vm.isPC(_vm.$store) ? _vm.canvasWidth : '100%'
      })},[_vm._v(" "+_vm._s(_vm.displayedInfoText)+" ")]),_c('div',{staticClass:"group-canvas-list"},[_c('b-card',{class:{ 'over-follow-x': !_vm.isPC(_vm.$store) },attrs:{"no-body":""}},[_c('b-tabs',{class:{
            'mb-tabs': !_vm.isPC(_vm.$store)
          },attrs:{"active-nav-item-class":"font-weight-bold text-uppercase text-danger","active-tab-class":"font-weight-bold text-success","content-class":"mt-3","content-class":{
            'mb-tab-content': !_vm.isPC(_vm.$store)
          },"card":"","end":""},scopedSlots:_vm._u([{key:"tabs-end",fn:function(){return [_c('b-nav-item',{attrs:{"role":"presentation","href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.addCanvas({ action: 'ADD_BLANK_CANVAS' })}}},[_c('b',[_vm._v("+")])])]},proxy:true},{key:"empty",fn:function(){return [_c('div',{staticClass:"text-center text-muted"},[_vm._v(" There are no open tabs"),_c('br'),_vm._v(" Open a new tab using the "),_c('b',[_vm._v("+")]),_vm._v(" button above. ")])]},proxy:true}]),model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},_vm._l((_vm.$store.state.designModule
              .canvasList),function(canvas,canvasIndex){return _c('b-tab',{key:canvas.id,class:{ 'padding-25': !_vm.isPC(_vm.$store) },scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s('Trang ' + "" + (canvasIndex + 1))+" "),_c('b-button',{staticClass:"button-close d-none",attrs:{"variant":"light","size":"sm"},on:{"click":function($event){return _vm.removeCanvas({ canvasId: canvas.id })}}},[_c('b-icon',{attrs:{"icon":"x-circle","variant":"danger","aria-hidden":"true"}})],1)]},proxy:true}],null,true)},[_c('div',{staticClass:"text-right m-auto",style:({
                width: _vm.canvasWidth
              })}),_c('div',{class:{
                'canvas-wrapper': true,
                'mt-2': true,
                active: canvas.id === _vm.$store.state.designModule.activeCanvasId
              },attrs:{"data-canvas-id":canvas.id}},[_c('canvas',{attrs:{"id":canvas.id}})])])}),1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }