<template>
  <div>
    <div class="product-name">
      <b-button block class="d-flex align-item-center">
        <span class="divider ml-2"></span>
        <strong class="text-center w-100 ml-auto">
          {{ image || '' }}
        </strong>
      </b-button>
    </div>
    <div class="template-details" v-if="activeImage && activeImage.length">
      <div class="mt-3">
        <vue-masonry-wall :items="activeImage" :options="options">
          <template v-slot:default="{ item }">
            <div class="Item">
              <b-button
                class="one-side-item button-image"
                @click="addImage(item.id)"
                :disabled="!activeCanvas"
                variant="outline-light"
              >
                <img :src="item.details[0].thumbnail" />
              </b-button>
            </div>
          </template>
        </vue-masonry-wall>
      </div>
    </div>
  </div>
</template>
<script>
import * as Sentry from '@sentry/vue';
import { BButton, BIcon } from 'bootstrap-vue';
import { RequestFactory } from '@Request/RequestFactory';
import { canvasHelpers } from '@/utils';
import { SET_SUB_MENU_IMAGE } from '@/store/designModule';
import VueMasonryWall from 'vue-masonry-wall';

const ProductRequest = RequestFactory.get('products');
const moduleName = 'designModule';
const defaultPerPage = 12;
const defaultPage = 1;

export default {
  name: 'SubMenuImage',
  components: {
    BButton,
    BIcon,
    VueMasonryWall
  },
  props: ['getActiveCanvas', 'image'],
  data() {
    return {
      nameMenu: '',
      slug: '',
      activeImage: [],
      options: {
        width: 150,
        padding: {
          2: 8,
          default: 12
        }
      }
    };
  },
  methods: {
    createImage: function(imgInput, callback) {
      let imgEl = new Image();
      imgEl.src = imgInput;
      imgEl.crossOrigin = 'anonymous';
      imgEl.onload = function() {
        callback && callback(new fabric.Image(imgEl));
      };
    },
    addImage: function(idImage) {
      const _this = this;
      const canvas = this.getActiveCanvas();
      try {
        if (!canvas) {
          return;
        }

        let image = null;
        this.activeImage.forEach(element => {
          if (element.id === idImage) {
            image = element;
            return true;
          }
        });

        this.createImage(image.details[0].thumbnail, function(bgImage) {
          const coord = canvasHelpers.getRandomLeftTop({
            canvas,
            obj: {
              width: 50,
              height: 50
            }
          });
          const { top, left } = coord;
          const scaleRatio = canvas.width / bgImage.width / 4;
          bgImage.set({
            scaleX: scaleRatio,
            scaleY: scaleRatio,
            left: Math.min(canvas.width - bgImage.width * scaleRatio, left),
            top: Math.min(canvas.height - bgImage.height * scaleRatio, top)
          });
          canvas.add(bgImage);
        });
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    async initMenuImage() {
      const subMenuImage = this.$store.state.designModule.subMenuImage;
      if (subMenuImage.categoryId !== null) {
        this.nameMenu = subMenuImage.name;
        this.activeImage = subMenuImage.imageList;
      } else {
        const appElement = this.$store.state.designModule.appElement;
        let elementId = null;
        appElement.forEach(element => {
          if (element.name === this.image) {
            elementId = element.id;
            this.nameMenu = element.name;
            return true;
          }
        });
        this.fetchDataSubMenuImageList(elementId, defaultPage);
      }
    },
    async fetchDataSubMenuImageList(categoryId, page) {
      try {
        const {
          data: { response }
        } = await ProductRequest.getProductsByCategoryAndTopic(
          categoryId,
          null,
          defaultPerPage,
          page
        );
        if (response.data.length) {
          this.activeImage = this.activeImage.concat(response.data);

          const store = this.$store;
          store.commit(`designModule/${SET_SUB_MENU_IMAGE}`, {
            categoryId: categoryId,
            name: this.nameMenu,
            currentPage: response.current_page,
            perPage: response.per_page,
            totalRows: response.total,
            imageList: this.activeImage
          });
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    async fetchImagePagination() {
      try {
        const {
          categoryId,
          currentPage,
          totalRows,
          imageList
        } = this.$store.state.designModule.subMenuImage;
        if (totalRows > imageList.length)
          this.fetchDataSubMenuImageList(categoryId, currentPage + 1);
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    onScroll() {
      const scrollSideBar = document.getElementsByClassName(
        'b-sidebar-body'
      )[0];
      if (
        scrollSideBar.scrollTop + scrollSideBar.clientHeight >=
        scrollSideBar.scrollHeight
      ) {
        this.fetchImagePagination();
      }
    }
  },
  created() {
    this.initMenuImage();
  },
  computed: {
    activeCanvas() {
      return this.$store.state[moduleName].activeCanvas;
    }
  },
  mounted() {
    const scrollSideBar = document.getElementsByClassName('b-sidebar-body')[0];
    scrollSideBar.addEventListener('scroll', this.onScroll);
  },
  beforeDestroy() {
    const scrollSideBar = document.getElementsByClassName('b-sidebar-body')[0];
    scrollSideBar.removeEventListener('scroll', this.onScroll);
  }
};
</script>
<style scoped>
.button-image {
  padding: 0px 2px;
  width: 100%;
  border: none;
}
.cus-col-2 {
  width: calc(100% / 6);
}
.cus-col-3 {
  width: calc(100% / 4);
}
.cus-col-4 {
  width: calc(100% / 3);
}
.cus-col-5 {
  width: calc(100% / 12 * 5);
}
.cus-col-6 {
  width: calc(100% / 2);
}
</style>
