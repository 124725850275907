<template>
  <b-popover
    :target="target"
    triggers="hover"
    placement="bottom"
    custom-class="set-width"
  >
    <template #title>Thông báo</template>
    Tính năng này hiện đang được phát triển!<br />
    Xin vui lòng thử lại sau.
  </b-popover>
</template>
<style scoped>
.set-width {
  min-width: 300px;
}
</style>
<script>
import { BPopover } from 'bootstrap-vue';

export default {
  name: 'UpComingPopover',
  components: {
    BPopover
  },
  props: ['target']
};
</script>
