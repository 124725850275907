import { render, staticRenderFns } from "./MainDesignArea.vue?vue&type=template&id=f218a752&scoped=true&"
import script from "./MainDesignArea.js?vue&type=script&lang=js&"
export * from "./MainDesignArea.js?vue&type=script&lang=js&"
import style0 from "./MainDesignArea.css?vue&type=style&index=0&id=f218a752&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f218a752",
  null
  
)

export default component.exports