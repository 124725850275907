import { mapGetters } from 'vuex';
import firebase from 'firebase';
import {
  BButton,
  BDropdown,
  BDropdownItemButton,
  BIcon,
  BTooltip
} from 'bootstrap-vue';
import { canvasHelpers } from '@/utils';
import { designIcons } from '@/utils/mediaData';
import { isPC } from '@/utils/commonUtils';
import OrderModalPc from './Modals/Order/Pc/OrderModalPc.vue';
import OrderModalMb from './Modals/Order/Mobile/OrderModalMb.vue';
import UpComingPopover from '../../../components/UpComingPopover/UpComingPopover.vue';

const moduleName = 'designModule';

const rightMenus = [
  {
    icon: designIcons.printIcon,
    text: 'Gửi in'
  },
  {
    icon: designIcons.downloadIcon,
    text: 'Tải xuống'
  },
  {
    icon: designIcons.loginIcon,
    text: 'Đăng nhập'
  }
];

export default {
  name: 'Header',
  components: {
    BButton,
    BDropdown,
    BDropdownItemButton,
    OrderModalPc,
    OrderModalMb,
    UpComingPopover,
    BIcon,
    BTooltip
  },
  data() {
    return {
      logo: designIcons.logo,
      login: designIcons.loginIcon,
      homeIcon: designIcons.homeIcon,
      shareIcon: designIcons.shareIcon,
      download: designIcons.downloadIcon,
      rightMenus,
      isPC
    };
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  methods: {
    signOut() {
      firebase.auth().signOut();
    },
    exportAllCanvasesToImage() {
      const store = this.$store;
      const { canvasList } = store.state[moduleName];

      const totalCanvases = canvasList.length;

      for (let i = 0; i < totalCanvases; i++) {
        const { id, canvas } = canvasList[i];
        const image = canvas.toDataURL({
          enableRetinaScaling: true
        });

        const downloadLink = document.createElement('a');
        downloadLink.href = image;
        downloadLink.download = `${id}.png`;

        downloadLink.click();
      }
    },
    exportSVG() {
      const store = this.$store;
      const { activeCanvas } = store.state[moduleName];
      const svgDataURL = canvasHelpers.exportToSVGDataURL({
        canvas: activeCanvas
      });

      console.log(svgDataURL);

      const downloadLink = document.createElement('a');
      downloadLink.href = svgDataURL;
      downloadLink.setAttribute('download', 'output.svg');

      downloadLink.click();
    },
    exportJSON() {
      const store = this.$store;
      const { activeCanvas } = store.state[moduleName];

      if (!activeCanvas) {
        return;
      }

      const json = JSON.stringify(activeCanvas);
      const downloadLink = document.createElement('a');
      downloadLink.href =
        'data:application/json;charset=utf-8,' + encodeURIComponent(json);
      downloadLink.download = 'data.json';

      downloadLink.click();
    }
  }
};
