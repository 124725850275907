<template>
  <div ref="icons">
    <div class="product-name">
      <b-button block class="d-flex align-item-center">
        <span class="divider ml-2"></span>
        <strong class="text-center w-100 ml-auto">
          {{ icon || '' }}
        </strong>
      </b-button>
    </div>
    <div class="template-details" v-if="activeIcon && activeIcon.length">
      <div
        class="row"
        :class="{
          'mt-3': !isMobile($store),
          'scrolling-wrapper flex-row flex-nowrap pt-2': isMobile($store)
        }"
      >
        <div
          v-for="icon in activeIcon"
          :key="icon.id"
          class="justify-content-center "
          :class="{
            'mb-4 col-lg-6': !isMobile($store),
            'col-6': isMobile($store)
          }"
        >
          <b-button
            class="one-side-item"
            @click="addIcon(icon.id)"
            style="width: 100%;height: 100%;"
            :disabled="!activeCanvas"
          >
            <b-aspect aspect="9:5">
              <img :src="icon.details[0].url" alt="template" />
            </b-aspect>
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as Sentry from '@sentry/vue';
import { BButton, BIcon, BAspect } from 'bootstrap-vue';
import { RequestFactory } from '@Request/RequestFactory';
import { canvasHelpers } from '@/utils';
import { SET_SUB_MENU_ICON } from '@/store/designModule';
import { isMobile } from '@/utils/commonUtils';

const ProductRequest = RequestFactory.get('products');
const moduleName = 'designModule';
const defaultPerPage = 12;
const defaultPage = 1;

export default {
  name: 'SubMenuIcon',
  components: {
    BButton,
    BIcon,
    BAspect
  },
  props: ['getActiveCanvas', 'icon'],
  data() {
    return {
      nameMenu: '',
      slug: '',
      activeIcon: [],
      isMobile
    };
  },
  methods: {
    addIcon: function(idIcon) {
      const canvas = this.getActiveCanvas();
      try {
        if (!canvas) {
          return;
        }

        let icon = null;
        this.activeIcon.forEach(element => {
          if (element.id === idIcon) {
            icon = element;
            return true;
          }
        });
        return canvasHelpers.addSVGContainerOneItem({
          canvas: canvas,
          url: icon.details[0].url
        });
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    async initMenuIcon() {
      const subMenuIcon = this.$store.state.designModule.subMenuIcon;
      if (subMenuIcon.categoryId !== null) {
        this.nameMenu = subMenuIcon.name;
        this.activeIcon = subMenuIcon.iconList;
      } else {
        const appElement = this.$store.state.designModule.appElement;
        let elementId = null;
        appElement.forEach(element => {
          if (element.name === this.icon) {
            elementId = element.id;
            this.nameMenu = element.name;
            return true;
          }
        });
        this.fetchDataSubMenuIconList(elementId, defaultPage);
      }
    },
    async fetchDataSubMenuIconList(categoryId, page) {
      try {
        const {
          data: { response }
        } = await ProductRequest.getProductsByCategoryAndTopic(
          categoryId,
          null,
          defaultPerPage,
          page
        );

        if (response.data.length) {
          this.activeIcon = this.activeIcon.concat(response.data);
          const store = this.$store;
          store.commit(`designModule/${SET_SUB_MENU_ICON}`, {
            categoryId: categoryId,
            name: this.nameMenu,
            currentPage: response.current_page,
            perPage: response.per_page,
            totalRows: response.total,
            iconList: this.activeIcon
          });
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    async fetchIconPagination() {
      try {
        const {
          categoryId,
          currentPage,
          totalRows,
          iconList
        } = this.$store.state.designModule.subMenuIcon;
        if (totalRows > iconList.length) {
          this.fetchDataSubMenuIconList(categoryId, currentPage + 1);
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    onScroll() {
      const scrollSideBar = document.getElementsByClassName(
        'b-sidebar-body'
      )[0];

      if (
        scrollSideBar.scrollTop + scrollSideBar.clientHeight >=
        scrollSideBar.scrollHeight
      ) {
        this.fetchIconPagination();
      }
    }
  },
  created() {
    this.initMenuIcon();
  },
  computed: {
    activeCanvas() {
      return this.$store.state[moduleName].activeCanvas;
    }
  },
  mounted() {
    const scrollSideBar = document.getElementsByClassName('b-sidebar-body')[0];
    scrollSideBar.addEventListener('scroll', this.onScroll);
  },
  beforeDestroy() {
    const scrollSideBar = document.getElementsByClassName('b-sidebar-body')[0];
    scrollSideBar.removeEventListener('scroll', this.onScroll);
  }
};
</script>
<style scoped>
/deep/ .scrolling-wrapper {
  overflow-x: auto;
}
</style>
