<template>
  <div>
    <!-- <div class="product-name">
      <b-button block class="d-flex align-item-center">
        <span class="divider ml-2"></span>
        <strong class="text-center w-100 ml-auto">
          {{ shape || '' }}
        </strong>
      </b-button>
    </div> -->
    <div class="template-details" v-if="activeShape">
      <div
        class="row"
        :class="{
          'mt-3': !isMobile($store),
          'scrolling-wrapper flex-row flex-nowrap pt-2': isMobile($store)
        }"
      >
        <div
          v-for="shape in activeShape"
          :key="shape.id"
          class="justify-content-center"
          :class="{
            'mb-4 col-lg-6': !isMobile($store),
            'col-6': isMobile($store)
          }"
        >
          <b-button
            class="one-side-item"
            @click="addShape(shape.id)"
            style="width: 100%; height: 141px"
            :disabled="!activeCanvas"
          >
            <b-aspect aspect="9:5">
              <img :src="shape.details[0].url" alt="template" />
            </b-aspect>
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as Sentry from '@sentry/vue';
import { BButton, BIcon, BAspect } from 'bootstrap-vue';
import { canvasHelpers } from '@/utils';
import { RequestFactory } from '@Request/RequestFactory';
import { SET_SUB_MENU_SHAPE } from '@/store/designModule';
import { isMobile } from '@/utils/commonUtils';

const ProductRequest = RequestFactory.get('products');
const moduleName = 'designModule';
const defaultPerPage = 12;
const defaultPage = 1;

export default {
  name: 'SubMenuShapeIcon',
  components: {
    BButton,
    BIcon,
    BAspect
  },
  props: ['getActiveCanvas', 'shape'],
  data() {
    return {
      nameMenu: '',
      slug: '',
      activeShape: [],
      isMobile
    };
  },
  methods: {
    addShape: function(idShape) {
      const canvas = this.getActiveCanvas();
      try {
        if (!canvas) {
          return;
        }
        let shape = null;
        this.activeShape.forEach(element => {
          if (element.id === idShape) {
            shape = element;
            return true;
          }
        });
        return canvasHelpers.addSVGContainerOneItem({
          canvas: canvas,
          url: shape.details[0].url
        });
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    async initMenuShape() {
      const subMenuShape = this.$store.state.designModule.subMenuShape;
      if (subMenuShape.categoryId !== null) {
        this.nameMenu = subMenuShape.name;
        this.activeShape = subMenuShape.shapeList;
      } else {
        const appElement = this.$store.state.designModule.appElement;
        let elementId = null;
        appElement.forEach(element => {
          if (element.name === this.shape) {
            elementId = element.id;
            this.nameMenu = element.name;
            return true;
          }
        });
        this.fetchDataSubMenuShapeList(elementId, defaultPage);
      }
    },
    async fetchDataSubMenuShapeList(categoryId, page) {
      try {
        const {
          data: { response }
        } = await ProductRequest.getProductsByCategoryAndTopic(
          categoryId,
          null,
          defaultPerPage,
          page
        );
        if (response.data.length) {
          this.activeShape = this.activeShape.concat(response.data);

          const store = this.$store;
          store.commit(`designModule/${SET_SUB_MENU_SHAPE}`, {
            categoryId: categoryId,
            name: this.nameMenu,
            currentPage: response.current_page,
            perPage: response.per_page,
            totalRows: response.total,
            shapeList: this.activeShape
          });
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    async fetchShapePagination() {
      try {
        const {
          categoryId,
          currentPage,
          totalRows,
          shapeList
        } = this.$store.state.designModule.subMenuShape;
        if (totalRows > shapeList.length)
          this.fetchDataSubMenuShapeList(categoryId, currentPage + 1);
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    onScroll() {
      const scrollSideBar = document.getElementsByClassName(
        'b-sidebar-body'
      )[0];
      if (
        scrollSideBar.scrollTop + scrollSideBar.clientHeight >=
        scrollSideBar.scrollHeight
      ) {
        this.fetchShapePagination();
      }
    },
    onScrollMobile() {
      const scrollSideBar = document.getElementsByClassName(
        'scrolling-wrapper'
      )[0];
      if (
        scrollSideBar.scrollLeft + scrollSideBar.clientWidth >=
        scrollSideBar.scrollWidth
      ) {
        this.fetchShapePagination();
      }
    }
  },
  created() {
    this.initMenuShape();
  },
  computed: {
    activeCanvas() {
      return this.$store.state[moduleName].activeCanvas;
    }
  },
  mounted() {
    this.$nextTick(() => {
      const scrollSideBar = document.getElementsByClassName(
        !isMobile(this.$store) ? 'b-sidebar-body' : 'scrolling-wrapper'
      )[0];
      scrollSideBar.addEventListener(
        'scroll',
        !isMobile(this.$store) ? this.onScroll : this.onScrollMobile
      );
    });
  },
  beforeDestroy() {
    const scrollSideBar = document.getElementsByClassName(
      !isMobile(this.$store) ? 'b-sidebar-body' : 'scrolling-wrapper'
    )[0];
    scrollSideBar.removeEventListener(
      'scroll',
      !isMobile(this.$store) ? this.onScroll : this.onScrollMobile
    );
  }
};
</script>
<style scoped>
/deep/ .scrolling-wrapper {
  overflow-x: auto;
}
</style>
