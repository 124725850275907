import { BButton, BIcon, BTabs, BTab, BCard, BNavItem } from 'bootstrap-vue';
import { mapGetters } from 'vuex';
import {
  ADD_CANVAS,
  REMOVE_CANVAS,
  SET_CANVAS_LIST,
  SET_ACTIVE_CANVAS,
  ACTIVE_CANVAS_ID,
  SET_OBJECT_COPY_CANVAS
} from '@/store/designModule';
import { genUniqueId, isPC } from '@/utils/commonUtils';
import { productHelpers } from '@/utils';

const moduleName = 'designModule';

export default {
  name: 'MainDesignArea',
  components: {
    BButton,
    BIcon,
    BTabs,
    BTab,
    BCard,
    BNavItem
  },
  data() {
    return {
      isPC,
      tabIndex: 0,
      window: {
        width: 0,
        height: 0
      }
    };
  },
  watch: {
    tabIndex(newTab, oldTab) {
      const { canvasList } = this.$store.state[moduleName];
      this.$store.commit(
        `${moduleName}/${SET_ACTIVE_CANVAS}`,
        canvasList[newTab]
      );
    }
  },
  methods: {
    /**
     * Common actions
     */
    addCanvas({ action = '' }) {
      const store = this.$store;
      const { activeCanvas, activeRatio } = store.state[moduleName];
      const newCanvasId = genUniqueId();
      const isAddBlankCanvas = action === 'ADD_BLANK_CANVAS';
      const isCloneActiveCanvas = action === 'CLONE_ACTIVE_CANVAS';
      const canvasJSONData = isAddBlankCanvas
        ? ''
        : JSON.stringify(activeCanvas);

      if (isCloneActiveCanvas && !activeCanvas) {
        return;
      }

      store.commit(`${moduleName}/${ADD_CANVAS}`, {
        canvasData: {
          id: newCanvasId,
          canvas: null
        }
      });

      /**
       * Why use $nextTick here?
       * - because fabric (in `canvasHelpers.initCanvas`) need canvas element
       *
       * Flow:
       * - `store.commit` above trigger re-render
       * - after DOM rendered, we have new canvas element in DOM
       * - `canvasHelpers.initCanvas` to init fabric canvas
       */
      this.$nextTick(() => {
        store.dispatch(`${moduleName}/initCanvas`, {
          id: newCanvasId
        });
      });
    },
    removeCanvas({ canvasId }) {
      const store = this.$store;
      const { activeCanvas } = store.state[moduleName];

      const r = confirm(
        'Thao tác này không thể hoàn tác. Bạn có muốn tiếp tục xóa?'
      );

      if (r == true) {
        store.commit(`${moduleName}/${REMOVE_CANVAS}`, {
          canvasId
        });

        this.$nextTick(() => {
          if (canvasId === activeCanvas.id) {
            store.dispatch(`${moduleName}/clearActiveCanvasState`);
          }
        });
      }

      return;
    }
  },
  mounted() {
    const _this = this;
    let map = { 17: false, 67: false, 86: false };
    // let copiedObjects = new Array();
    const store = _this.$store;
    const { activeItemByProductId } = store.state[moduleName];
    const svgURLs = productHelpers.getAllSVGURLsFromDetails(
      activeItemByProductId
    );

    const canvasList = svgURLs.map((svgURL, index) => ({
      id: genUniqueId(),
      canvas: null,
      templateId: activeItemByProductId.details[index].id,
      svgURL
    }));
    store.commit(`${moduleName}/${SET_CANVAS_LIST}`, {
      canvasList
    });

    store.commit(`${moduleName}/${ACTIVE_CANVAS_ID}`, canvasList[0].id);

    this.$nextTick(() => {
      window.CANVAS_WIDTH_PX = this.$refs.canvas.clientWidth;
      window.CANVAS_HEIGTH_PX = window.innerHeight - 130 - 50 - 24;
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
      store.dispatch(`${moduleName}/initAllCanvas`);
    });
    document.addEventListener(
      'click',
      function(event) {
        const { target } = event;
        const classes = target.getAttribute('class') || '';
        const isOutsideCanvas = classes.indexOf('canvas-wrapper') > -1;
        const { activeCanvas } = store.state[moduleName];

        if (isOutsideCanvas && activeCanvas) {
          store.dispatch(`${moduleName}/clearActiveCanvasState`);

          return;
        }
      },
      false
    );

    // ctrl + c and ctrl + v fabric
    document.addEventListener('keydown', e => {
      const { activeCanvas, canvasList, objectCopyCanvas } = store.state[
        moduleName
      ];
      let canvas = canvasList[_this.tabIndex].canvas;
      if (activeCanvas) {
        if (e.keyCode in map) {
          map[e.keyCode] = true;

          if (map[17] && map[67]) {
            //ctrl + c
            e.preventDefault();
            // if (canvas.getActiveGroup()!== undefined) {
            //   for (var i in canvas.getActiveGroup().objects) {
            //     var object = fabric.util.object.clone(canvas.getActiveGroup().objects[i]);
            //     object.set("top", object.top);
            //     object.set("left", object.left);
            //     copiedObjects[i] = object;
            //   }
            // }
            // else
            if (canvas.getActiveObject()) {
              var object = fabric.util.object.clone(canvas.getActiveObject());
              object.set('top', object.top + 5);
              object.set('left', object.left + 5);
              store.commit(`${moduleName}/${SET_OBJECT_COPY_CANVAS}`, object);
              // copiedObjects = new Array();
            }
            map = { 17: false, 67: false, 86: false };
          } else if (map[17] && map[86]) {
            //ctrl + v
            e.preventDefault();
            // if (copiedObjects.length > 0) {
            //   for (var i in copiedObjects) {
            //     canvas.add(copiedObjects[i]);
            //   }
            // } else
            if (objectCopyCanvas) {
              canvas.discardActiveObject();
              var object = fabric.util.object.clone(objectCopyCanvas);
              object.set('top', object.top + 5);
              object.set('left', object.left + 5);
              canvas.add(object);
              //active canvas
              canvas.setActiveObject(object);
              canvas.requestRenderAll();

              //set position
              var objectSetPosition = fabric.util.object.clone(
                objectCopyCanvas
              );
              objectSetPosition.set('top', objectSetPosition.top + 5);
              objectSetPosition.set('left', objectSetPosition.left + 5);
              store.commit(
                `${moduleName}/${SET_OBJECT_COPY_CANVAS}`,
                objectSetPosition
              );
              // copiedObjects = new Array();
            }
            map = { 17: false, 67: false, 86: false };
          }
        }
      }
    });
  },
  computed: {
    ...mapGetters({
      activeProduct: `${moduleName}/activeProduct`,
      nameCategory: `${moduleName}/nameCategory`
    }),
    displayedInfoText() {
      const { activeRatio } = this.$store.state[moduleName];
      const { name } = this.nameCategory || {};
      const { ratioWidth, ratioHeight } = activeRatio;

      return `${name}: ${ratioWidth}x${ratioHeight} (cm)`;
    },
    showAddPageButton() {
      const { canvasList } = this.$store.state[moduleName];
      const { maxPages } = this.activeProduct || {};
      const totalCanvas = canvasList.length;

      return totalCanvas < maxPages;
    },
    canvasWidth() {
      const { canvasList } = this.$store.state[moduleName];

      if (canvasList && canvasList[0] && canvasList[0].canvas) {
        return `${canvasList[0].canvas.width}px`;
      }

      return '100%';
    }
  }
};
