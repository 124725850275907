<template>
  <div>
    <div
      class="np-editor-template mt-3 mb-3"
      :class="{ 'two-col': isPC($store), 'one-col': !isPC($store) }"
      :style="!isShowTemplateList ? 'display: grid;' : ''"
      ref="transition"
    >
      <div v-show="isShowTemplateList">
        <div v-if="activeProduct" class="template-list">
          <div class="product-name">
            <b-button
              block
              class="d-flex align-item-center"
              target="_blank"
              :to="{ name: 'category', params: { category: categorySlug } }"
            >
              <b-icon icon="chevron-left"></b-icon>
              <span
                class="divider ml-2"
                style="border-right: 1px solid #808080; height: 24px"
              ></span>
              <strong class="text-center w-100 ml-auto">
                {{ categoryName || '' }}
              </strong>
            </b-button>
          </div>

          <div
            class="template-details"
            v-if="activeProduct && activeProduct.length"
          >
            <div class="labels" v-if="!isOneSideActiveProduct && isPC($store)">
              <div>Mặt trước</div>
              <div>Mặt sau</div>
            </div>

            <div
              class="mt-3"
              :class="{
                row: isOneSideActiveProduct
              }"
            >
              <div
                class="mb-4 justify-content-center"
                :class="{
                  'col-lg-6': isOneSideActiveProduct,
                  'd-flex': !isOneSideActiveProduct
                }"
                v-for="template in activeProduct"
                :key="template.id"
                :value="template.id"
              >
                <b-button
                  v-if="!isOneSideActiveProduct"
                  class="mr-2 add-all-item"
                  @click="onApplyAllTemplate(template)"
                >
                  +
                </b-button>

                <b-button
                  class="one-side-item"
                  v-if="isOneSideActiveProduct"
                  @click="
                    onApplyTemplateAndChangeRoute({
                      productId: template.details[0].product_id,
                      slug: template.category.slug,
                      svgURL: template.details[0].url
                    })
                  "
                  :disabled="!activeCanvas"
                >
                  <b-aspect aspect="9:5">
                    <img :src="template.details[0].thumbnail" alt="template" />
                  </b-aspect>
                </b-button>

                <div
                  v-else
                  :class="{
                    'template-item': isPC($store),
                    'template-item-one-row': !isPC($store)
                  }"
                >
                  <b-button
                    @click="
                      onApplyTemplate({
                        productId: template.details[0].product_id,
                        templateId: template.details[0].id,
                        svgURL: template.details[0].url
                      })
                    "
                    :disabled="!activeCanvas"
                  >
                    <b-aspect aspect="9:5">
                      <img
                        :src="template.details[0].thumbnail"
                        alt="Front side"
                      />
                    </b-aspect>
                  </b-button>
                  <b-button
                    @click="
                      onApplyTemplate({
                        productId: template.details[1].product_id,
                        templateId: template.details[1].id,
                        svgURL: template.details[1].url
                      })
                    "
                    :disabled="!activeCanvas"
                  >
                    <b-aspect aspect="9:5">
                      <img
                        :src="template.details[1].thumbnail"
                        alt="Back side"
                      />
                    </b-aspect>
                  </b-button>
                </div>
              </div>
            </div>
          </div>
          <div v-else style="margin: 20px 0">Nothing to show</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Sentry from '@sentry/vue';
import { canvasHelpers, productHelpers, eventBus } from '@/utils';
import { RequestFactory } from '@Request/RequestFactory';
import {
  genUniqueId,
  onTrackClick,
  isPC,
  subStringProduct
} from '@/utils/commonUtils';
import {
  BAspect,
  BButton,
  BIcon,
  BFormCheckboxGroup,
  BFormCheckbox,
  BFormGroup
} from 'bootstrap-vue';
import {
  SET_ACTIVE_CANVAS,
  SET_CANVAS_LIST,
  ACTIVE_CANVAS_ID
} from '@/store/designModule';
import { SET_PRODUCT_LIST_BY_CATEGORY } from '@/store/app';

const ProductRequest = RequestFactory.get('products');

const moduleName = 'designModule';
const defaultPerPage = 12;
const defaultPage = 1;

export default {
  name: 'SubMenuProductList',
  components: {
    BAspect,
    BButton,
    BIcon,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormGroup
  },
  data() {
    return {
      isShowTemplateList: true,
      activeProduct: [],
      selected: [],
      isPC,
      categoryName: null,
      categorySlug: null
    };
  },
  watch: {
    $route(to, from) {
      const loader = this.$loading.show();
      const store = this.$store;
      const productParamsRoute = this.$route.params.product;
      const productId = parseInt(subStringProduct(productParamsRoute)[1]);
      let template;
      this.activeProduct.forEach(element => {
        if (element.id === productId) {
          template = element;
          return true;
        }
      });

      const svgURLs = productHelpers.getAllSVGURLsFromDetails(template);

      const canvasList = svgURLs.map((svgURL, index) => ({
        id: genUniqueId(),
        canvas: null,
        templateId: template.details[index].id,
        svgURL
      }));

      store.commit(`${moduleName}/${SET_CANVAS_LIST}`, {
        canvasList
      });

      store.commit(`${moduleName}/${ACTIVE_CANVAS_ID}`, canvasList[0].id);

      this.$nextTick(() => {
        store.dispatch(`${moduleName}/initAllCanvas`);
        loader.hide();
      });
    }
  },
  computed: {
    activeCanvas() {
      return this.$store.state[moduleName].activeCanvas;
    },
    isOneSideActiveProduct() {
      const activeProduct = this.activeProduct;

      if (activeProduct && activeProduct.length) {
        const firstTemplateItem = activeProduct[0];

        return productHelpers.isOneSideTemplate(firstTemplateItem);
      }

      return false;
    }
  },
  created() {
    this.fetchDataProductCategory();
  },
  methods: {
    onMouseDown({ id, canvas }) {
      /**
       * TODO
       * Need a better way to achieve this
       * This is duplicated
       */
      const store = this.$store;

      return function(_opt) {
        store.commit(`${moduleName}/${SET_ACTIVE_CANVAS}`, {
          id,
          canvas
        });
      };
    },
    onChooseProduct(item) {
      eventBus.emit('keep-open-transition');
      this.isShowTemplateList = true;
      this.activeProduct = item;

      // send tracking gtag
      onTrackClick('#id_' + item.productType, this.$gtag);
    },
    // onOpenProductList(e) {
    //   e.preventDefault();
    //   this.isShowTemplateList = false;
    //   this.activeProduct = null;
    // },
    onCloseMenuDetails() {
      this.isShowTemplateList = false;
      this.activeProduct = null;
    },
    onApplyTemplate({ productId, templateId, svgURL }) {
      /**
       * TODO
       * - Save active product to store
       * - Apply selected template to active canvas
       */
      const store = this.$store;
      const storeState = store.state;
      const { activeCanvas } = storeState.designModule;

      if (!activeCanvas) {
        return;
      }

      let r = confirm('Bản thiết kế cũ sẽ bị thay thế. Bạn có muốn tiếp tục?');

      if (r === true) {
        const loader = this.$loading.show();

        // if (isChangedProduct) {
        //   store.commit(`${moduleName}/${CHANGE_PRODUCT}`, {
        //     activeProductId: productId,
        //     activeRatio: ratio,
        //   });

        //   const newCanvasList = canvasList.map((c) => {
        //     if (c.id === activeCanvasId) {
        //       return {
        //         ...c,
        //         templateId,
        //       };
        //     }

        //     return c;
        //   });

        //   store.commit(`${moduleName}/${SET_CANVAS_LIST}`, {
        //     canvasList: newCanvasList,
        //   });

        //   activeCanvas.setDimensions({
        //     width: ratio.ratioWidth * window.CM_TO_PX,
        //     height: ratio.ratioHeight * window.CM_TO_PX,
        //   });
        // }

        canvasHelpers.loadSVG({
          canvas: activeCanvas,
          url: svgURL
        });

        loader.hide();
      }
    },
    onApplyTemplateAndChangeRoute({ productId, slug, svgURL }) {
      /**
       * TODO
       * - Save active product to store
       * - Apply selected template to active canvas
       */
      const store = this.$store;
      const storeState = store.state;
      const { activeCanvas } = storeState.designModule;

      if (!activeCanvas) {
        return;
      }

      let r = confirm('Bản thiết kế cũ sẽ bị thay thế. Bạn có muốn tiếp tục?');

      if (r === true) {
        // const loader = this.$loading.show();
        // canvasHelpers.loadSVG({
        //   canvas: activeCanvas,
        //   url: svgURL
        // });

        let paramProduct = slug + '-' + productId;
        if (this.$route.params.product != paramProduct) {
          this.$router.push({
            name: 'product',
            params: { product: paramProduct }
          });
        }

        // loader.hide();
      }
    },
    onApplyAllTemplate(template) {
      /**
       * Apply all template in active product to all canvas
       * - Clear old canvas list in the store
       * - Create new canvas list in the store
       * - Loop and init each canvas with each template from active product
       */
      const r = confirm(
        'Bản thiết kế cũ sẽ bị thay thế. Bạn có muốn tiếp tục?'
      );

      if (!r || productHelpers.isOneSideTemplate(template)) {
        return;
      }

      let paramProduct = template.category.slug + '-' + template.id;
      if (this.$route.params.product != paramProduct) {
        this.$router.push({
          name: 'product',
          params: { product: paramProduct }
        });
      }
    },
    async fetchDataProductCategory() {
      const product = this.$route.params.product;
      const productSlug = subStringProduct(product);
      const productListByCategory = this.$store.state.app.productListByCategory;
      if (productSlug[0] === productListByCategory.categorySlug) {
        this.categoryName = productListByCategory.categoryName;
        this.categorySlug = productListByCategory.categorySlug;
        this.activeProduct = productListByCategory.productList;
      } else {
        const nameCategorySlug = productSlug[0];
        let categoryId = null;
        const productCategoryList = this.$store.state.app.productCategoryList;
        productCategoryList.forEach(element => {
          if (element.slug === nameCategorySlug) {
            categoryId = element.id;
            this.categoryName = element.name;
            this.categorySlug = element.slug;
            return true;
          }
        });
        this.fetchDataSubMenuProductList(productSlug, categoryId, defaultPage);
      }
    },
    async fetchProductPagination() {
      try {
        const {
          categoryId,
          categorySlug,
          currentPage,
          totalRows,
          productList
        } = this.$store.state.app.productListByCategory;
        if (totalRows > productList.length)
          this.fetchDataSubMenuProductList(
            categorySlug,
            categoryId,
            currentPage + 1
          );
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    async fetchDataSubMenuProductList(productSlug, categoryId, page) {
      try {
        const {
          data: { response }
        } = await ProductRequest.getProductsByCategoryAndTopic(
          categoryId,
          null,
          defaultPerPage,
          page
        );

        this.activeProduct.push(...response.data);
        const store = this.$store;
        store.commit(`app/${SET_PRODUCT_LIST_BY_CATEGORY}`, {
          categorySlug: productSlug[0],
          categoryId: categoryId,
          categoryName: this.categoryName,
          currentPage: response.current_page,
          perPage: response.per_page,
          totalRows: response.total,
          productList: this.activeProduct
        });
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    onScroll() {
      const scrollSideBar = document.getElementsByClassName(
        'b-sidebar-body'
      )[0];
      if (
        scrollSideBar.scrollTop + scrollSideBar.clientHeight >=
        scrollSideBar.scrollHeight - 10
      ) {
        this.fetchProductPagination();
      }
    }
  },
  mounted() {
    eventBus.on('on-close-details-menu', this.onCloseMenuDetails);
    this.$nextTick(() => {
      const scrollSideBar = document.getElementsByClassName(
        'b-sidebar-body'
      )[0];
      scrollSideBar.addEventListener('scroll', this.onScroll);
    });
  },
  beforeDestroy() {
    eventBus.off('on-close-details-menu', this.onCloseMenuDetails);

    const scrollSideBar = document.getElementsByClassName('b-sidebar-body')[0];
    scrollSideBar.removeEventListener('scroll', this.onScroll);
  }
};
</script>

<style>
.np-editor-template.two-col {
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
}
.np-editor-template .item {
  border-radius: 13px;
  background-color: #272c30;
  color: #fff;
  height: 130px;
  padding: 19px 0;
  cursor: pointer;
  font-size: 12px;
  border: none;
}
.np-editor-template .item img {
  padding-bottom: 15px;
  height: 55px;
  transition: transform 0.2s;
}
.np-editor-template .item:hover img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}
.np-editor-template .item hr {
  margin: 0 auto 8px auto;
  background-color: #2b2b2b;
  width: 60px;
  border-top: 1px dashed #fff;
}
.np-editor-template .template-list {
  color: #fff;
  margin-top: -7px;
}
.np-editor-template .template-list .product-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0 14px 0;
}
.np-editor-template .template-list .product-name button {
  background-color: #272c30;
  border: none;
}
.np-editor-template .template-details .labels {
  width: 100%;
  padding-left: 42px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  margin: 5px 0 20px 0;
}
.np-editor-template .template-details .labels div {
  background-color: #272c30;
  padding: 3px 0;
  border-radius: 5px;
}
.np-editor-template .template-details .add-all-item {
  background-color: #272c30;
  border: none;
}
.np-editor-template .template-details .template-item {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
}
.np-editor-template .template-details .template-item button {
  background-color: #272c30;
  border: none;
  padding: 0;
}
.np-editor-template .template-details .template-item button img,
.np-editor-template .template-details .one-side-item img {
  transition: transform 0.2s;
}
.np-editor-template .template-details .template-item button:hover img,
.np-editor-template .template-details .one-side-item:hover img {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}
.np-editor-template .template-details .one-side-item {
  background: none;
  border: none;
}
/* Start Custom CSS for mobile */
.np-editor-template.one-col .item {
  margin-bottom: 15px;
}
.np-editor-template .template-details .template-item-one-row button {
  background-color: #272c30;
  border: none;
  padding: 0;
}
.np-editor-template
  .template-details
  .template-item-one-row
  button:first-child {
  margin-bottom: 10px;
}
/* End Custom CSS for mobile */
</style>
