import {
  BDropdown,
  BDropdownItemButton,
  BButton,
  BTooltip,
  BForm,
  BFormSelect,
  BFormSelectOption,
  BIcon,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormInput,
  BContainer,
  BRow,
  BCol
} from 'bootstrap-vue';
import { mapActions } from 'vuex';
// import CanvasRatioDialog from '../CanvasRatioDialog/CanvasRatioDialog.vue';
import { designIcons, copyIcon, pasteIcon } from '@/utils/mediaData';
import HeaderMb from '../Header/Mobile/HeaderMb.vue';
import { isPC } from '@/utils/commonUtils';

const moduleName = 'designModule';

const translations = {
  fontFamily: 'Kiểu chữ',
  fontSize: 'Kích thước chữ',
  noneColor: 'Không chọn màu',
  fill: 'Màu sắc',
  stroke: 'Màu viền',
  align: 'Canh lề',
  alignLeft: 'Canh trái',
  alignRight: 'Canh phải',
  alignCenter: 'Canh giữa',
  alignJustify: 'Canh đều',
  bold: 'In đậm',
  italic: 'In nghiêng',
  underline: 'Gạch dưới',
  sendBackwards: 'Xuống một lớp',
  sendToBack: 'Xuống dưới cùng',
  bringForward: 'Lên một lớp',
  bringToFront: 'Lên trên cùng',
  opacity: 'Độ trong suốt',
  layer: 'Sắp xếp đối tượng'
};

// https://stackoverflow.com/questions/58407403/event-handler-for-object-selection-on-fabricjs-canvas

export default {
  name: 'ActionToolbar',
  components: {
    BDropdown,
    BDropdownItemButton,
    BButton,
    BTooltip,
    BForm,
    BFormSelect,
    BFormSelectOption,
    BIcon,
    BCol,
    BRow,
    BContainer,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormInput,
    HeaderMb
    // CanvasRatioDialog
  },
  data() {
    return {
      icons: {
        bg: designIcons.bg,
        opacityIcon: designIcons.opacityIcon
      },
      translations,
      isPC,
      copyIcon,
      pasteIcon
    };
  },
  computed: {
    hasAnySelected() {
      const store = this.$store;
      const { hasActiveObject, hasActiveObjectText } = store.state[moduleName];

      return hasActiveObject || hasActiveObjectText;
    },
    getAlignIcon() {
      const store = this.$store;
      const { textAlign } = store.state[moduleName].activeObjectStyle;

      return textAlign === 'justify' ? 'justify' : 'text-' + textAlign;
    },
    isAlignLeft() {
      const store = this.$store;
      const { textAlign } = store.state[moduleName].activeObjectStyle;

      return textAlign === 'left';
    },
    isAlignCenter() {
      const store = this.$store;
      const { textAlign } = store.state[moduleName].activeObjectStyle;

      return textAlign === 'center';
    },
    isAlignRight() {
      const store = this.$store;
      const { textAlign } = store.state[moduleName].activeObjectStyle;

      return textAlign === 'right';
    },
    isAlignJustify() {
      const store = this.$store;
      const { textAlign } = store.state[moduleName].activeObjectStyle;

      return textAlign === 'justify';
    },
    isBold() {
      const store = this.$store;
      const { fontStyle } = store.state[moduleName].activeObjectStyle;

      return fontStyle['bold'];
    },
    isItalic() {
      const store = this.$store;
      const { fontStyle } = store.state[moduleName].activeObjectStyle;

      return fontStyle['italic'];
    },
    isUnderline() {
      const store = this.$store;
      const { fontStyle } = store.state[moduleName].activeObjectStyle;

      return fontStyle['underline'];
    },
    isUndoDisabled() {
      const store = this.$store;
      const { activeCanvas } = store.state[moduleName];

      if (activeCanvas && activeCanvas.historyUndo) {
        return activeCanvas.historyUndo && !activeCanvas.historyUndo.length;
      }

      return true;
    },
    isRedoDisabled() {
      const store = this.$store;
      const { activeCanvas, isRedoDisabled } = store.state[moduleName];

      if (activeCanvas && activeCanvas.historyRedo) {
        let x = activeCanvas.historyRedo && !activeCanvas.historyRedo.length;

        return x ? true : isRedoDisabled ? true : false;
      }

      return true;
    },
    isPasteDisabled() {
      const store = this.$store;
      const { objectCopyCanvas } = store.state[moduleName];
      return !objectCopyCanvas ? true : false;
    }
  },
  methods: {
    /**
     * All helpers
     */
    ...mapActions(moduleName, ['handleUndo', 'handleRedo']),
    getSelectedObject() {
      const store = this.$store;
      const { activeCanvas } = store.state[moduleName];

      if (activeCanvas) {
        const object = activeCanvas.getActiveObject();

        return object;
      }

      return null;
    },

    /**
     * All common actions for all objects
     */
    handleFill(e) {
      const store = this.$store;
      const value = typeof e === 'string' ? e : e.target.value;

      store.dispatch(`${moduleName}/updateActiveObjectStyle`, {
        name: 'fill',
        value
      });
    },
    handleStroke(e) {
      const store = this.$store;
      const value = typeof e === 'string' ? e : e.target.value;

      store.dispatch(`${moduleName}/updateActiveObjectStyle`, {
        name: 'stroke',
        value
      });
    },
    handleOpacity(value) {
      const store = this.$store;

      store.dispatch(`${moduleName}/updateActiveObjectStyle`, {
        name: 'opacity',
        value
      });
    },
    handleSendBackwards() {
      const store = this.$store;
      const { activeCanvas } = store.state[moduleName];
      const object = this.getSelectedObject();

      if (object) {
        activeCanvas.sendBackwards(object);
      }
    },
    handleSendToBack() {
      const store = this.$store;
      const { activeCanvas } = store.state[moduleName];
      const object = this.getSelectedObject();

      if (object) {
        activeCanvas.sendToBack(object);
      }
    },
    handleBringForwards() {
      const store = this.$store;
      const { activeCanvas } = store.state[moduleName];
      const object = this.getSelectedObject();

      if (object) {
        activeCanvas.bringForward(object);
      }
    },
    handleBringToFront() {
      const store = this.$store;
      const { activeCanvas } = store.state[moduleName];
      const object = this.getSelectedObject();

      if (object) {
        activeCanvas.bringToFront(object);
      }
    },

    /**
     * All ONLY text actions
     */
    handleFontFamily(value) {
      const store = this.$store;

      store.dispatch(`${moduleName}/updateActiveObjectStyle`, {
        name: 'fontFamily',
        value
      });
    },
    handleFontSize(value) {
      const store = this.$store;

      store.dispatch(`${moduleName}/updateActiveObjectStyle`, {
        name: 'fontSize',
        value
      });
    },
    handleDecreaseFontSize() {
      const store = this.$store;
      const value = Number(store.state[moduleName].activeObjectStyle.fontSize);

      if (value > 1) {
        store.dispatch(`${moduleName}/updateActiveObjectStyle`, {
          name: 'fontSize',
          value: value - 1
        });
      }
    },
    handleIncreaseFontSize() {
      const store = this.$store;
      const value = Number(store.state[moduleName].activeObjectStyle.fontSize);

      if (value < 200) {
        store.dispatch(`${moduleName}/updateActiveObjectStyle`, {
          name: 'fontSize',
          value: value + 1
        });
      }
    },
    handleTextAlign(value) {
      const store = this.$store;

      store.dispatch(`${moduleName}/updateActiveObjectStyle`, {
        name: 'textAlign',
        value
      });
    },
    handleTextStyle(value) {
      const store = this.$store;

      store.dispatch(`${moduleName}/updateActiveObjectStyle`, {
        name: 'fontStyle',
        value
      });
    },
    handleCopy() {
      const store = this.$store;
      store.dispatch(`${moduleName}/setObjectCopy`);
    },
    handlePaste() {
      const store = this.$store;
      store.dispatch(`${moduleName}/setObjectPaste`);
    }
  }
};
