<template>
  <div>
    <b-row class="p-2">
      <b-col
        cols="12"
        v-for="font in $store.state.designModule.appData.fontList"
        :key="font.fontFamily"
        class="p-2"
        @click="addText(font.fontFamily)"
      >
        <b-button
          block
          class="text-center text-white list-fonts"
          :style="{ fontFamily: `'${font.fontFamily}'` }"
          v-text="font.name"
        >
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BButton, BRow, BCol } from 'bootstrap-vue';
import { textHelpers } from '@/utils';

const moduleName = 'designModule';

export default {
  name: 'SubMenuProductFonts',
  components: {
    BButton,
    BRow,
    BCol
  },
  props: ['getActiveCanvas'],
  methods: {
    addText: function(fontFamily) {
      const canvas = this.getActiveCanvas();
      if (!canvas) {
        return;
      }

      const zoomCount = this.zoomCount || 0;

      textHelpers.addText({
        canvas,
        fontFamily,
        zoomCount
      });
    }
  }
};
</script>
<style>
.list-fonts {
  background-color: #272c30;
  border: none;
  font-size: 22px;
  padding: 10px 5px;
}
</style>
