import {
  BForm,
  BFormInput,
  BFormGroup,
  BButton,
  BModal,
  BTooltip,
  BFormSelect
} from 'bootstrap-vue';
import { mapGetters } from 'vuex';
import { productHelpers } from '@/utils';
import { SET_CURRENT_RATIO } from '@/store/designModule';

const moduleName = 'designModule';

export default {
  name: 'CanvasRatioDialog',
  components: {
    BForm,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BButton,
    BModal,
    BTooltip
  },
  data() {
    return {
      form: {
        ratioWidth: '',
        ratioHeight: '',
        selectedSize: ''
      }
    };
  },
  computed: {
    ...mapGetters({
      activeProduct: `${moduleName}/activeProduct`
    }),
    displayedMaximumRatio() {
      if (!this.activeProduct) {
        return 'Error'; // TODO
      }

      const { productName, maxRatioWidth, maxRatioHeight } = this.activeProduct;

      return `*Kích thước tối đa cho ${productName}: ${maxRatioWidth}x${maxRatioHeight}(cm)`;
    },
    isValidForm() {
      if (this.activeProduct.canModify) {
        const { maxRatioWidth, maxRatioHeight } = this.activeProduct;
        const { ratioWidth, ratioHeight } = this.form;

        const isWidthValid =
          !isNaN(ratioWidth) &&
          Number(ratioWidth) > 0 &&
          Number(ratioWidth) <= maxRatioWidth;
        const isHeightValid =
          !isNaN(ratioHeight) &&
          Number(ratioHeight) > 0 &&
          Number(ratioHeight) <= maxRatioHeight;

        return isWidthValid && isHeightValid;
      } else {
      }
    },
    sizeOptions() {
      return this.activeProduct.productSize.map(s => ({
        value: `${s.ratioWidth}x${s.ratioHeight}`,
        text: s.name
      }));
    }
  },
  methods: {
    changeCanvasSize() {
      const store = this.$store;
      const { activeCanvas, activeRatio } = store.state.designModule;
      const { ratioWidth, ratioHeight } = activeRatio;

      // TODO
      // Need to update to store?
      activeCanvas.setDimensions({
        width: ratioWidth * window.CM_TO_PX,
        height: ratioHeight * window.CM_TO_PX
      });
    },
    resetRatio() {
      const store = this.$store;
      const { productList } = store.state.app;
      const defaultRatio = productHelpers.getProductRatioById({
        productList,
        id: this.activeProduct.id
      });

      if (defaultRatio) {
        this.form.ratioWidth = defaultRatio.ratioWidth;
        this.form.ratioHeight = defaultRatio.ratioHeight;

        store.commit(`${moduleName}/${SET_CURRENT_RATIO}`, {
          activeRatio: defaultRatio
        });

        this.changeCanvasSize();
      }

      this.$refs['canvas-ratio-dialog'].hide();
    },
    setCustomAspectRatio() {
      const store = this.$store;

      if (this.activeProduct.canModify) {
        const { ratioWidth, ratioHeight } = this.form;

        if (!this.isValidForm) {
          this.$toastr.e(`Vui lòng kiểm tra lại dữ liệu.`);
          return;
        }

        store.commit(`${moduleName}/${SET_CURRENT_RATIO}`, {
          activeRatio: {
            ratioWidth: Number(ratioWidth),
            ratioHeight: Number(ratioHeight)
          }
        });

        this.changeCanvasSize();
      } else {
        // TODO
        /**
         * How to
         * - store current value locally
         * - Onsubmit, save to store
         */
        const splited = this.form.selectedSize.split('x');
        const ratioWidth = splited[0];
        const ratioHeight = splited[1];

        store.commit(`${moduleName}/${SET_CURRENT_RATIO}`, {
          activeRatio: {
            ratioWidth: Number(ratioWidth),
            ratioHeight: Number(ratioHeight)
          }
        });

        this.changeCanvasSize();
      }

      this.$nextTick(() => {
        this.$refs['canvas-ratio-dialog'].hide();
      });
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.setCustomAspectRatio();
    }
  },
  mounted() {
    /**
     * mounted has been called only one time??
     */
    const store = this.$store;
    const { activeRatio } = store.state.designModule;
    const { ratioWidth, ratioHeight } = activeRatio;
    const { canModify } = this.activeProduct;

    if (canModify) {
      this.form = {
        ...this.form,
        ratioWidth,
        ratioHeight
      };
    } else {
      this.form = {
        ...this.form,
        selectedSize: `${ratioWidth}x${ratioHeight}`
      };
    }
  }
};
