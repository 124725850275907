var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{ref:"transition",staticClass:"np-editor-template mt-3 mb-3",class:{ 'two-col': _vm.isPC(_vm.$store), 'one-col': !_vm.isPC(_vm.$store) },style:(!_vm.isShowTemplateList ? 'display: grid;' : '')},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowTemplateList),expression:"isShowTemplateList"}]},[(_vm.activeProduct)?_c('div',{staticClass:"template-list"},[_c('div',{staticClass:"product-name"},[_c('b-button',{staticClass:"d-flex align-item-center",attrs:{"block":"","target":"_blank","to":{ name: 'category', params: { category: _vm.categorySlug } }}},[_c('b-icon',{attrs:{"icon":"chevron-left"}}),_c('span',{staticClass:"divider ml-2",staticStyle:{"border-right":"1px solid #808080","height":"24px"}}),_c('strong',{staticClass:"text-center w-100 ml-auto"},[_vm._v(" "+_vm._s(_vm.categoryName || '')+" ")])],1)],1),(_vm.activeProduct && _vm.activeProduct.length)?_c('div',{staticClass:"template-details"},[(!_vm.isOneSideActiveProduct && _vm.isPC(_vm.$store))?_c('div',{staticClass:"labels"},[_c('div',[_vm._v("Mặt trước")]),_c('div',[_vm._v("Mặt sau")])]):_vm._e(),_c('div',{staticClass:"mt-3",class:{
              row: _vm.isOneSideActiveProduct
            }},_vm._l((_vm.activeProduct),function(template){return _c('div',{key:template.id,staticClass:"mb-4 justify-content-center",class:{
                'col-lg-6': _vm.isOneSideActiveProduct,
                'd-flex': !_vm.isOneSideActiveProduct
              },attrs:{"value":template.id}},[(!_vm.isOneSideActiveProduct)?_c('b-button',{staticClass:"mr-2 add-all-item",on:{"click":function($event){return _vm.onApplyAllTemplate(template)}}},[_vm._v(" + ")]):_vm._e(),(_vm.isOneSideActiveProduct)?_c('b-button',{staticClass:"one-side-item",attrs:{"disabled":!_vm.activeCanvas},on:{"click":function($event){return _vm.onApplyTemplateAndChangeRoute({
                    productId: template.details[0].product_id,
                    slug: template.category.slug,
                    svgURL: template.details[0].url
                  })}}},[_c('b-aspect',{attrs:{"aspect":"9:5"}},[_c('img',{attrs:{"src":template.details[0].thumbnail,"alt":"template"}})])],1):_c('div',{class:{
                  'template-item': _vm.isPC(_vm.$store),
                  'template-item-one-row': !_vm.isPC(_vm.$store)
                }},[_c('b-button',{attrs:{"disabled":!_vm.activeCanvas},on:{"click":function($event){return _vm.onApplyTemplate({
                      productId: template.details[0].product_id,
                      templateId: template.details[0].id,
                      svgURL: template.details[0].url
                    })}}},[_c('b-aspect',{attrs:{"aspect":"9:5"}},[_c('img',{attrs:{"src":template.details[0].thumbnail,"alt":"Front side"}})])],1),_c('b-button',{attrs:{"disabled":!_vm.activeCanvas},on:{"click":function($event){return _vm.onApplyTemplate({
                      productId: template.details[1].product_id,
                      templateId: template.details[1].id,
                      svgURL: template.details[1].url
                    })}}},[_c('b-aspect',{attrs:{"aspect":"9:5"}},[_c('img',{attrs:{"src":template.details[1].thumbnail,"alt":"Back side"}})])],1)],1)],1)}),0)]):_c('div',{staticStyle:{"margin":"20px 0"}},[_vm._v("Nothing to show")])]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }