<template>
  <div>
    <!-- <div class="product-name">
      <b-button block class="d-flex align-item-center">
        <span class="divider ml-2"></span>
        <strong class="text-center w-100 ml-auto">
          {{ cartoon || '' }}
        </strong>
      </b-button>
    </div> -->
    <div class="template-details" v-if="activeCartoon">
      <div
        class="row "
        :class="{
          'mt-3': !isMobile($store),
          'scrolling-wrapper flex-row flex-nowrap pt-2': isMobile($store)
        }"
      >
        <div
          v-for="cartoon in activeCartoon"
          :key="cartoon.id"
          class="justify-content-center"
          :class="{
            'mb-4 col-lg-6': !isMobile($store),
            'col-6': isMobile($store)
          }"
        >
          <b-button
            class="one-side-item"
            @click="addCartoon(cartoon.id)"
            style="width: 100%; height: 141px"
            :disabled="!activeCanvas"
          >
            <b-aspect aspect="9:5" style="max-height: 100%">
              <img
                :src="cartoon.details[0].url"
                alt="template"
                style="max-height: 100%"
              />
            </b-aspect>
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as Sentry from '@sentry/vue';
import { BButton, BIcon, BAspect } from 'bootstrap-vue';
import { RequestFactory } from '@Request/RequestFactory';
import { canvasHelpers } from '@/utils';
import { SET_SUB_MENU_CARTOON } from '@/store/designModule';
import { isMobile } from '@/utils/commonUtils';

const ProductRequest = RequestFactory.get('products');
const moduleName = 'designModule';
const defaultPerPage = 12;
const defaultPage = 1;

export default {
  name: 'SubMenuCartoon',
  components: {
    BButton,
    BIcon,
    BAspect
  },
  props: ['getActiveCanvas', 'cartoon'],
  data() {
    return {
      nameMenu: '',
      slug: '',
      activeCartoon: [],
      isMobile
    };
  },
  methods: {
    addCartoon: function(idCartoon) {
      const canvas = this.getActiveCanvas();
      try {
        if (!canvas) {
          return;
        }

        let cartoon = null;
        this.activeCartoon.forEach(element => {
          if (element.id === idCartoon) {
            cartoon = element;
            return true;
          }
        });
        return canvasHelpers.addSVGContainerOneItem({
          canvas: canvas,
          url: cartoon.details[0].url
        });
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    async initMenuCartoon() {
      const subMenuCartoon = this.$store.state.designModule.subMenuCartoon;
      if (subMenuCartoon.categoryId !== null) {
        this.nameMenu = subMenuCartoon.name;
        this.activeCartoon = subMenuCartoon.cartoonList;
      } else {
        const appElement = this.$store.state.designModule.appElement;
        let elementId = null;
        appElement.forEach(element => {
          if (element.name === this.cartoon) {
            elementId = element.id;
            this.nameMenu = element.name;
            return true;
          }
        });
        this.fetchDataSubMenuCartoonList(elementId, defaultPage);
      }
    },
    async fetchDataSubMenuCartoonList(categoryId, page) {
      try {
        const {
          data: { response }
        } = await ProductRequest.getProductsByCategoryAndTopic(
          categoryId,
          null,
          defaultPerPage,
          page
        );

        if (response.data.length) {
          this.activeCartoon = this.activeCartoon.concat(response.data);
          const store = this.$store;
          store.commit(`designModule/${SET_SUB_MENU_CARTOON}`, {
            categoryId: categoryId,
            name: this.nameMenu,
            currentPage: response.current_page,
            perPage: response.per_page,
            totalRows: response.total,
            cartoonList: this.activeCartoon
          });
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    async fetchCartoonPagination() {
      try {
        const {
          categoryId,
          currentPage,
          totalRows,
          cartoonList
        } = this.$store.state.designModule.subMenuCartoon;
        if (totalRows > cartoonList.length)
          this.fetchDataSubMenuCartoonList(categoryId, currentPage + 1);
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    onScroll() {
      const scrollSideBar = document.getElementsByClassName(
        'b-sidebar-body'
      )[0];

      if (
        scrollSideBar.scrollTop + scrollSideBar.clientHeight >=
        scrollSideBar.scrollHeight
      ) {
        this.fetchCartoonPagination();
      }
    },
    onScrollMobile() {
      const scrollSideBar = document.getElementsByClassName(
        'scrolling-wrapper'
      )[0];
      if (
        scrollSideBar.scrollLeft + scrollSideBar.clientWidth >=
        scrollSideBar.scrollWidth
      ) {
        this.fetchCartoonPagination();
      }
    }
  },
  created() {
    this.initMenuCartoon();
  },
  computed: {
    activeCanvas() {
      return this.$store.state[moduleName].activeCanvas;
    }
  },
  mounted() {
    this.$nextTick(() => {
      const scrollSideBar = document.getElementsByClassName(
        !isMobile(this.$store) ? 'b-sidebar-body' : 'scrolling-wrapper'
      )[0];
      scrollSideBar.addEventListener(
        'scroll',
        !isMobile(this.$store) ? this.onScroll : this.onScrollMobile
      );
    });
  },
  beforeDestroy() {
    const scrollSideBar = document.getElementsByClassName(
      !isMobile(this.$store) ? 'b-sidebar-body' : 'scrolling-wrapper'
    )[0];
    scrollSideBar.removeEventListener(
      'scroll',
      !isMobile(this.$store) ? this.onScroll : this.onScrollMobile
    );
  }
};
</script>
<style scoped>
/deep/ .scrolling-wrapper {
  overflow-x: auto;
}
</style>
